import React from 'react'
import * as Yup from 'yup'

import Amex from '@/components/Icons/Amex'
import CreditCard from '@/components/Icons/CreditCard'
import Mastercard from '@/components/Icons/Mastercard'
import Visa from '@/components/Icons/Visa'

export const getCardLogoForBrand = (brand?: string) => {
  switch (brand) {
    case 'visa':
      return <Visa />

    case 'mastercard':
      return <Mastercard />

    case 'amex':
      return <Amex />

    default:
      return <CreditCard />
  }
}

export const inputValidationSchema = Yup.object().shape({
  totalInCents: Yup.number(),
  paymentIntentId: Yup.string().required(
    'Missing payment intent id. Please wait a moment and retry, or refresh the page',
  ),
})
