import { useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest, Stripe } from '@stripe/stripe-js'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import isObject from 'lodash/fp/isObject'
import values from 'lodash/fp/values'
import React, { useContext, useEffect, useState } from 'react'
import { CombinedError, useQuery } from 'urql'

import ApplePay from '@/components/Icons/ApplePay'
import CreditCard from '@/components/Icons/CreditCard'
import GooglePay from '@/components/Icons/GooglePay'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import {
  CustomerStripeCardDocument,
  GuestVenuePaymentProcessorType,
  OrderingType,
  PaymentFormQuery,
  PaymentProcessorType,
  StripeCardPartsFragment,
} from '@/gql/graphql'
import { reportErrorContext } from '@/lib/bugsnag'
import { BookingDetails, CustomerStoreContext } from '@/stores/CustomerStore'
import { getStripePlatformAccountCountryCodeForCurrency } from '@/utils/stripe'

import { PaymentMethodValues } from '../PaymentMethodSchema'
import { getCardLogoForBrand } from '../Stripe/utils'

export type PaymentMethod = {
  /**
   * Should be unique
   */
  key: PaymentMethodKey
  /**
   * Polymorphic, whatever the paymentMethod requires to be passed
   */
  value: string | null
  processorType: PaymentProcessorType
  position: number
  Icon: React.ElementType
  label: string | React.ReactNode
  tracking: string
  visible?: boolean
  fetching?: boolean
}

export type PaymentMethodValue = Pick<
  PaymentMethod,
  'key' | 'value' | 'processorType' | 'tracking'
>

export enum QuickPaymentMethodKey {
  applePay = 'Apple Pay',
  googlePay = 'Google Pay',
}
export interface StripePaymentRequestMethod extends PaymentMethod {
  key: PaymentMethodKey.StripePaymentRequest
  label: QuickPaymentMethodKey
  tracking: QuickPaymentMethodKey
  value: null
}

export interface RegularPaymentMethod extends PaymentMethod {
  value: string | null
}

export const isStripePaymentRequest = (
  paymentMethod: PaymentMethodValues['paymentMethod'],
): paymentMethod is StripePaymentRequestMethod =>
  paymentMethod?.key === PaymentMethodKey.StripePaymentRequest

export const isRegularPaymentMethod = (
  paymentMethod: PaymentMethodValues['paymentMethod'],
): paymentMethod is RegularPaymentMethod =>
  !isStripePaymentRequest(paymentMethod)

interface UsePaymentMethodArgs {
  venue: NonNullable<PaymentFormQuery['guestVenue']>
  totalInCents: number
  paymentFormData: NonNullable<PaymentFormQuery>
}

export interface UsePaymentMethodsReturnType {
  paymentRequest?: PaymentRequest | null
  paymentRequestFetching: boolean
  paymentMethods: PaymentMethod[]
  /**
   * Is any paymentMethod still fetching ?
   */
  paymentMethodsFetching: boolean
  paymentMethodsErrors: CombinedError[]
  submitError?: Error | null
}

export enum PaymentMethodKey {
  /** @deprecated - Migrated to V2 */
  TabNew = 'tab-new',
  /** @deprecated - Migrated to V2 */
  TabExisting = 'tab-existing',
  StripeExistingCard = 'stripe-existing',
  StripeNewCard = 'stripe-new-card',
  StripePaymentRequest = 'stripe-payment-request',
  /** @deprecated - Migrated to V2 */
  PaystackExistingCard = 'paystack-existing',
  /** @deprecated - Migrated to V2 */
  PaystackNewCard = 'paystack-new-card',
  /** @deprecated - Migrated to V2 */
  PaystackPaymentRequest = 'paystack-payment-request',
  /** @deprecated - Removed */
  Snapscan = 'snapscan',
  Loke = 'loke',
  /** @deprecated - Removed */
  Afterpay = 'afterpay',
  /** @deprecated - Removed */
  ChargeToRoom = 'charge-to-room',
  /** @deprecated - Migrated to V2 */
  Cash = 'cash',
  /** @deprecated - Migrated to V2 */
  Unpaid = 'unpaid',
  /** @deprecated - Migrated to V2 */
  Eonx = 'eonx',
}

interface PaymentMethodOptions {
  paymentFormData: NonNullable<PaymentFormQuery>
  orderingType?: OrderingType
  hasStripe?: boolean
  stripeCard?: StripeCardPartsFragment | null
  fetchingStripeCard?: boolean
  hasTabsEnabled?: boolean
  paymentRequest?: PaymentRequest
  applePay?: boolean
  googlePay?: boolean
  paymentRequestFetching?: boolean
  bookingDetails?: BookingDetails
  totalInCents?: number | undefined
}

export const getPaymentMethods = (
  opts: PaymentMethodOptions,
): PaymentMethod[] => {
  const paymentMethods: PaymentMethod[] = [
    {
      key: PaymentMethodKey.StripeExistingCard,
      value: opts.stripeCard?.paymentMethodId ?? null,
      processorType: PaymentProcessorType.Stripe,
      position: 3,
      label: opts.stripeCard ? (
        <>
          {opts.stripeCard.brand.toUpperCase()} &bull;&bull;&bull;&bull;{' '}
          {opts.stripeCard.last4} {opts.stripeCard.expiry}
        </>
      ) : null,
      tracking: 'Existing Stripe card',
      Icon: () => getCardLogoForBrand(opts.stripeCard?.brand),
      visible: opts.hasStripe && (opts.fetchingStripeCard || !!opts.stripeCard),
      fetching: opts.fetchingStripeCard || !opts.stripeCard,
    },
    {
      key: PaymentMethodKey.StripePaymentRequest,
      value: null,
      processorType: PaymentProcessorType.Stripe,
      position: 2,
      label: opts.applePay
        ? QuickPaymentMethodKey.applePay
        : QuickPaymentMethodKey.googlePay,
      tracking: opts.applePay
        ? QuickPaymentMethodKey.applePay
        : QuickPaymentMethodKey.googlePay,
      Icon: opts.applePay ? ApplePay : GooglePay,
      visible:
        opts.hasStripe &&
        (opts.paymentRequestFetching || !!opts.paymentRequest),
      fetching: opts.paymentRequestFetching,
    },
    {
      key: PaymentMethodKey.StripeNewCard,
      value: null,
      processorType: PaymentProcessorType.Stripe,
      position: 4,
      label: 'New credit/debit card',
      tracking: 'New Stripe card',
      Icon: CreditCard,
      visible: opts.hasStripe,
    },
  ]

  return paymentMethods
    .flat()
    .map((item) => ({
      ...item,
      visible: item.visible,
    }))
    .filter((paymentMethod) => paymentMethod.visible === true)
    .sort((a, b) => a.position - b.position)
}

/**
 * Return available payment methods
 */
export const usePaymentFormMethods = ({
  venue,
  totalInCents,
  paymentFormData,
}: UsePaymentMethodArgs): UsePaymentMethodsReturnType => {
  const { venueSlug } = useVenueContext()
  const { orderingType } = useOrderingTypeContext()
  const paymentProcessorTypes = venue.paymentProcessor?.types || []
  const customerId = paymentFormData.currentUser?.customerId

  const { getBookingDetailsForVenueSlug } = useContext(CustomerStoreContext)
  const bookingDetails = getBookingDetailsForVenueSlug(venueSlug)

  const hasStripe = paymentProcessorTypes.includes(
    GuestVenuePaymentProcessorType.Stripe,
  )

  /**
   * Stripe Hook
   */
  const stripe = useStripe()

  /**
   * Existing customer card
   * Won't load if Stripe is not enabled
   */
  const [
    {
      data: stripeCardData,
      fetching: fetchingStripeCard,
      error: stripeCardError,
    },
  ] = useQuery({
    query: CustomerStripeCardDocument,
    variables: { customerId: customerId!, currency: String(venue?.currency) },
    pause: !hasStripe || !customerId || !venue?.currency,
  })
  const stripeCard = stripeCardData?.stripeCard

  /**
   * Apple / Google Pay
   * Won't load if Stripe is not enabled
   */
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()
  const [paymentRequestFetching, setPaymentRequestFetching] = useState(false)
  const [applePay, setApplePay] = useState(false)
  const [googlePay, setGooglePay] = useState(false)
  const currency = venue.currency

  // we need this to only run once on page load once we have a venue, totalInCents, stripe and venue
  useEffect(() => {
    async function checkPaymentRequestAvailable(
      stripe: Stripe,
      totalInCents: number,
      currency: string,
    ) {
      try {
        setPaymentRequestFetching(true)
        const pr = stripe.paymentRequest({
          country: getStripePlatformAccountCountryCodeForCurrency(currency),
          currency: currency.toLowerCase(),
          total: {
            label: 'me&u',
            amount: totalInCents,
          },
        })

        const result = await pr.canMakePayment()
        if (!result) {
          return
        }
        if (result.applePay) setApplePay(true)
        if (result.googlePay) setGooglePay(true)
        if (result.applePay || result.googlePay) {
          setPaymentRequest(pr)
        }
      } catch (error) {
        reportErrorContext(error, 'stripe')
      } finally {
        setPaymentRequestFetching(false)
      }
    }

    if (hasStripe && stripe && totalInCents && currency) {
      void checkPaymentRequestAvailable(stripe, totalInCents, currency)
    }
  }, [hasStripe, stripe, totalInCents, currency])

  const paymentMethods = getPaymentMethods({
    paymentFormData,
    orderingType,
    stripeCard,
    hasStripe,
    fetchingStripeCard,
    paymentRequest,
    applePay,
    googlePay,
    paymentRequestFetching,
    bookingDetails,
    totalInCents,
  })

  const paymentMethodsFetching =
    isEmpty(paymentMethods) ||
    !flow(filter({ fetching: true }), isEmpty)(paymentMethods)

  return {
    paymentRequest,
    paymentRequestFetching,
    paymentMethods,
    paymentMethodsFetching,
    paymentMethodsErrors: flow(
      values,
      filter(isObject),
    )({
      customerCardError: stripeCardError,
    }) as CombinedError[],
  }
}
