import { IntlShape } from 'react-intl'
import { z } from 'zod'

import {
  OrderingType,
  PaymentFormQuery,
  VenueFieldRequirement,
} from '@/gql/graphql'

import { paymentMethodSchema } from './PaymentMethodSchema'

interface GetPaymentSchemaParams {
  hasOptions: boolean
  showEmailFieldWhenDineIn: boolean
  paymentFormData?: PaymentFormQuery
  orderingType: OrderingType | null
  emailFieldRequirement: VenueFieldRequirement
  coverCountFieldRequirement: VenueFieldRequirement
}

export function getPaymentSchema(
  params: GetPaymentSchemaParams,
  intl: IntlShape,
) {
  return z
    .object({
      venueSlug: z.string({
        required_error: intl.formatMessage({
          defaultMessage: 'Venue slug is required',
          id: '1NzuOL',
        }),
      }),
      type: z.nativeEnum(OrderingType, {
        required_error: intl.formatMessage({
          defaultMessage: 'Ordering type is required',
          id: 'lx5PHW',
        }),
      }),
      cardToken: z.string().nullable().optional(),
      name: z
        .string({
          message: intl.formatMessage({
            id: '1KxtyA',
            defaultMessage: 'Your name is required',
          }),
        })
        .trim()
        .min(
          1,
          intl.formatMessage({
            defaultMessage: 'Your name is required',
            id: '1KxtyA',
          }),
        ),
      email: z
        .string()
        .trim()
        .email(
          intl.formatMessage({
            id: 'qDmele',
            defaultMessage: 'Email must be valid',
          }),
        )
        .nullable()
        .optional(),
      coverCount: z
        .number({
          coerce: true,
          message: intl.formatMessage({
            id: 'mG+rlo',
            defaultMessage: 'Cover count is required',
          }),
        })
        .min(
          1,
          intl.formatMessage({
            id: 'GaaUEa',
            defaultMessage: 'Requires a number between 1 and 20',
          }),
        )
        .max(
          20,
          intl.formatMessage({
            id: 'GaaUEa',
            defaultMessage: 'Requires a number between 1 and 20',
          }),
        )
        .nullable()
        .optional(),
      isOver18: z.boolean().nullable().optional(),
      pickupOption: z.string().nullable().optional(),
      deliveryOption: z.string().nullable().optional(),
      roomName: z.string().nullable().optional(),
      roomNumber: z.string().nullable().optional(),
      marketingUserOptIn: z.boolean().nullable().optional(),
      marketingUserOptInVisible: z.boolean().nullable().optional(),
      reApplyVenueWide: z.boolean().default(true),
      savePaymentDetails: z.boolean().nullable().optional(),
      paymentPath: z.string().nullable().optional(),
    })
    .merge(paymentMethodSchema)
    .superRefine((data, ctx) => {
      if (
        params.paymentFormData?.guestVenue?.ageRestrictionText &&
        !data.isOver18
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({
            id: 'Q5SxGZ',
            defaultMessage: 'Confirmation is needed to continue',
          }),
          path: ['isOver18'],
        })
      }

      if (
        params.hasOptions &&
        params.orderingType &&
        ![OrderingType.InVenue, OrderingType.Delivery].includes(
          params.orderingType,
        ) &&
        !data.pickupOption
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({
            id: '0xNxPW',
            defaultMessage: 'Please choose an option',
          }),
          path: ['pickupOption'],
        })
      }

      if (
        params.coverCountFieldRequirement === VenueFieldRequirement.Required &&
        !data.coverCount
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({
            id: 'mG+rlo',
            defaultMessage: 'Cover count is required',
          }),
          path: ['coverCount'],
        })
      }

      if (
        params.hasOptions &&
        params.orderingType &&
        [OrderingType.InVenue, OrderingType.Delivery].includes(
          params.orderingType,
        ) &&
        !data.deliveryOption
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({
            id: '0xNxPW',
            defaultMessage: 'Please choose an option',
          }),
          path: ['deliveryOption'],
        })
      }

      if (
        (params.emailFieldRequirement === VenueFieldRequirement.Required ||
          (data.marketingUserOptIn && data.marketingUserOptInVisible)) &&
        !data.email
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage({
            id: 'gtleS5',
            defaultMessage: 'Please provide your email address',
          }),
          path: ['email'],
        })
      }
    })
    .transform((data) => {
      if (
        params.emailFieldRequirement === VenueFieldRequirement.Hidden &&
        !params.showEmailFieldWhenDineIn
      ) {
        delete data.email
      }

      if (params.coverCountFieldRequirement === VenueFieldRequirement.Hidden) {
        delete data.coverCount
      }

      return data
    })
}

export type PaymentValues = z.input<ReturnType<typeof getPaymentSchema>>
