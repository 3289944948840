import { z } from 'zod'

import { PaymentProcessorType } from '@/gql/graphql'

import { PaymentMethodKey } from './hooks/usePaymentFormMethods'

/*
  These are the payment methods where we access paymentMethod.value in order to process transaction.
  These should ideally be static values that don't change at runtime after we first load data and
  set initial form values.
*/
export const paymentMethodKeysRequiringValue = [
  PaymentMethodKey.StripeExistingCard,
]

export const AcceptedPaymentProcessorTypes = [
  PaymentProcessorType.Stripe,
  PaymentProcessorType.Loke,
]

export const paymentMethodSchema = z.object({
  paymentMethod: z
    .object({
      key: z.nativeEnum(PaymentMethodKey),
      value: z.any(),
      tracking: z.string().nullable(),
      processorType: z.nativeEnum(PaymentProcessorType),
    })
    .nullable()
    .superRefine((paymentMethod, ctx) => {
      if (paymentMethod === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Payment method is required',
        })

        return
      }

      if (
        !AcceptedPaymentProcessorTypes.includes(paymentMethod.processorType)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This payment method is no longer supported',
          path: ['processorType'],
        })
      }

      if (
        paymentMethodKeysRequiringValue.includes(paymentMethod.key) &&
        !paymentMethod.value
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Payment method value is required',
          path: ['value'],
        })
      }
    }),
})

export type PaymentMethodValues = z.infer<typeof paymentMethodSchema>
